<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12 >
                <material-card title="Factories"
                               text="">

                    <template :slot="'header_actions'">
                        <v-btn small fab rounded
                               v-tooltip="'New Factory'" color="white"
                               @click="set_factory_fields('new', {})"
                               uk-toggle="target: #admin-edit-data-modal">
                            <v-icon color="primary">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-data-table :headers="headers"
                                  :items="factories"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>

                        <template slot="headers" slot-scope="props">

                            <tr>
                                <th v-for="header in props.headers"
                                    :key="header.value"
                                    ref="fixed_cell" v-tooltip="header.alt || header.text">

                                    <span class="subheading font-weight-light">
                                        <v-icon v-if="header.sortable" small>mdi-menu-up</v-icon>
                                        <span v-if="!header.icon">{{ header.text }}</span>
                                        <v-icon v-else>{{ header.icon }}</v-icon>
                                    </span>

                                </th>

                            </tr>
                        </template>

                        <template slot="items" slot-scope="props">

                            <td v-for="header in headers" :key="header.id">

                                <v-layout v-if="!header.custom">
                                    <span class="text-truncate uk-width-small">
                                        {{ props.item[header.value] }}
                                    </span>
                                </v-layout>
                                <v-layout v-if="header.custom">
                                    <v-layout v-if="header.value === 'actions'">
                                        <v-btn
                                                uk-tooltip="title: Edit Factory"
                                                rounded
                                                text
                                                uk-toggle="target: #admin-edit-data-modal"
                                                @click="set_factory_fields('update', props.item)"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>


                                        <v-btn
                                                uk-tooltip="title: Delete Factory"
                                                @click=""
                                                rounded
                                                text
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>

                                    </v-layout>
                                </v-layout>
                            </td>

                        </template>

                    </v-data-table>

                </material-card>
            </v-flex>

            <div id="admin-edit-data-modal" class="uk-modal-container" uk-modal>
                <div class="uk-card uk-modal-dialog uk-width-1-1">
                    <button class="uk-modal-close-outside" type="button" uk-close></button>
                    <cpanel-edit-data :title="form_title" :db_link="db_link" :type="form_type" :ed_fields="ed_fields" ></cpanel-edit-data>
                </div>
            </div>
        </v-layout>
    </v-container>

</template>

<script>

    /**
     * TODO: Fix modal so when it closes set v-if on modal wrapper, otherwise it breaks
     */

    export default {
        name: "factories",
        data: () => ({
            db_link: '',
            ed_fields: [],
            form_type: 'new',
            form_title: '',
            title: "factories",
            loading:true,
            factories: [],
            headers: [
                {
                    text: 'Name',
                    align: 'center',
                    value: 'name'
                },
                {
                    text: 'Address',
                    align: 'center',
                    value: 'address_line_one'
                },
                {
                    text: 'Country',
                    align: 'center',
                    value: 'country'
                },
                {
                    text: 'Email',
                    align: 'center',
                    value: 'email'
                },
                {
                    text: 'Website',
                    align: 'center',
                    value: 'website'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    value: 'actions',
                    custom: true
                },
            ],
            show_modal: false
        }),
        methods: {
            set_factory_fields(type, factory) {
                this.form_type = type;
                this.form_title = 'New Factory';
                this.db_link = '/admins/factories/';
                this.ed_fields = [
                    {
                        label: 'Factory User',
                        type: 'select',
                        options: [],
                        track_by: 'id',
                        async: true,
                        multiple: false,
                        async_url: '/admins/search/users/6/',
                        show: 'email',
                        value: [],
                        table: 'factory_user',
                        required: true,
                        allow_new: true,
                        allow_new_table: 'user',
                        allow_new_fields: [
                            {
                                label: 'First Name',
                                type: 'text',
                                value: '',
                                table: 'first_name',
                                required: true,
                                rules: []
                            },
                            {
                                label: 'Last Name',
                                type: 'text',
                                value: '',
                                table: 'last_name',
                                required: true,
                                rules: []
                            },
                            {
                                label: 'Username',
                                type: 'text',
                                value: '',
                                table: 'username',
                                required: true,
                                rules: []
                            },
                            {
                                label: 'Email',
                                type: 'email',
                                value: '',
                                table: 'email',
                                required: true,
                                rules: []
                            },
                            {
                                label: 'Password',
                                type: 'password',
                                value: '',
                                table: 'password',
                                required: true,
                                rules: []
                            },
                            {
                                label: 'Group',
                                type: 'hidden',
                                value: '6',
                                table: 'group',
                                required: true,
                                rules: []
                            },
                        ],
                        rules: []
                    },

                    {
                        label: 'Name',
                        type: 'text',
                        value: '',
                        table: 'name',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Address',
                        type: 'textarea',
                        value: '',
                        table: 'address_line_one',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Region',
                        type: 'text',
                        value: '',
                        table: 'region',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Zip Code',
                        type: 'text',
                        value: '',
                        table: 'zip_code',
                        required: true,
                        rules: []
                    },

                    {
                        label: 'Country',
                        type: 'text',
                        value: 'China',
                        table: 'country',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Contact Name',
                        type: 'text',
                        value: '',
                        table: 'contact_name',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Contact Number',
                        type: 'number',
                        value: '',
                        table: 'contact_number',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Email',
                        type: 'email',
                        value: '',
                        table: 'email',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'Website',
                        type: 'text',
                        value: '',
                        table: 'website',
                        required: true,
                        rules: []
                    },
                    {
                        label: 'FOB Location',
                        type: 'text',
                        value: '',
                        table: 'fob_location',
                        required: true,
                        rules: []
                    },

                    {
                        label: 'State ID',
                        type: 'hidden',
                        value: '1',
                        table: 'state',
                        required: true,
                        rules: []
                    },


                ];
                if (type === 'update') {
                    this.form_title = "Editing " + factory.name;

                    this.db_link = '/admins/factory/' + factory.id;

                    for(let value of this.ed_fields){
                        let search_key = value.table;
                        if (typeof factory[search_key] != 'undefined' && value.type !== 'hidden') {
                            value.value = factory[search_key];
                        }

                        if (value.async) {
                            if (value.multiple)
                                value.options = factory[search_key];
                            else
                                value.options = [factory[search_key]];
                        }
                    }
                    console.log(this.ed_fields);
                }
            }
        },
        created() {

            this.$http.get('/admins/factories/')
                .then((response) => {
                    console.log(response.data);
                    this.loading = false;
                    this.factories = response.data.results
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        }
    }
</script>

<style scoped>

</style>
